import React from 'react';
import { useTranslation } from "react-i18next";
import { useLocation, Link } from 'react-router-dom';
import Content from '../containers/Content';
import ContactForm from '../forms/ContactForm';
import Title from '../components/Title';

const ContactContent = () => {
  const { t } = useTranslation();
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const initialSubject = searchParams.get('subject');

  return (
    <Content className="px-8 mx-auto">
      <div className="flex flex-col my-auto gap-8 sm:w-[450px] lg:w-[900px]">
        <Title title={t('contact_page.header')} className={"text-center font-sans"}></Title>
        <div className="flex-1 flex flex-col lg:flex-row gap-4">
          {/* Vasen puoli */}
          <div className="flex-1 lg:px-2">
            <div className="flex flex-col gap-2 text-base leading-relaxed font-medium ">
              <div>{t("contact_page.subtext1")}</div>
              <div>{t("contact_page.subtext2")}</div>
              <div>
                {t("contact_page.subtext3_1")} 
                <Link to="/contactinfo" className="font-medium underline hover:text-highlight">
                  {t("contactinfo_page.header")}
                </Link>
                {t("contact_page.subtext3_2")}
              </div>
            </div>
            
            <div className="lg:hidden flex border-b border-px border-white pt-4"></div>
          </div>
          <div className="lg:border-r border-white"></div>
          {/* Lomake */}
          <div className="flex-1 lg:px-2">
            <ContactForm initialSubject={initialSubject} />
          </div>
        </div>
      </div>
    </Content>
  );
};

export default ContactContent;
