import React from 'react';
import { useTranslation } from "react-i18next";
import Content from '../containers/Content';
import ContactCard from '../components/contact/ContactCard';
import GeneralContact from '../components/contact/GeneralContact';
import MapEmbed from '../components/MapEmbed';
import Title from '../components/Title';
import CommonButton from '../components/CommonButton';
import contacts from '../assets/contacts/contacts.json';

const ContactInfo = () => {
  const { t } = useTranslation();

  return (
    <Content className="px-8 mx-auto lg:w-full">
      <div className="flex flex-col my-auto gap-6">

        <Title title={t('contactinfo_page.header')} className={"text-center"}></Title>

        {/* Jaetaan sivu isolla näytöllä kahteen */}
        <div className="flex flex-col lg:flex-row gap-4">

          {/* Vasen puoli */}
          <div className="flex-1">
            <div className="flex flex-col">
              {/* Johto kortit */}
              <div className="mb-2">
                <div className="h-8 text-lg font-semibold font-special">{t('contactinfo_page.management')}</div>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                  <ContactCard contact={contacts[0]} />
                  <ContactCard contact={contacts[1]} />
                </div>
              </div>
              {/* Osasto kortit */}
              <div className="">
                <div className="h-8 text-lg font-semibold font-special">{t('contactinfo_page.departments')}</div>
                <div className="relative">
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                    <ContactCard contact={contacts[2]} />
                    <ContactCard contact={contacts[3]} />
                    <ContactCard contact={contacts[4]} />
                    <CommonButton
                      className="sm:absolute sm:right-0 sm:bottom-0 sm:mt-4 px-6 py-3 border-white hover:border-highlight hover:shadow-subtle-glow
                      transition-transform transform hover:scale-105"
                      to={t("contactinfo_page.all_contacts.path")}
                      specialBorder>
                      {t("contactinfo_page.all_contacts.label")}
                    </CommonButton>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Oikea puoli */}
          {/* Jaetaan kahteen kolumniin */}
          <div className="sm:mt-6 flex-1 flex flex-col lg:flex-row gap-4 lg:border-l lg:pl-4 border-white">
            <div className="flex-1 flex flex-col gap-3">
              {/* Yleiset yhteystiedot */}
              <GeneralContact contactKey={"general"} />
              <GeneralContact contactKey={"invoice"} />
              <GeneralContact contactKey={"maintenance"} />
            </div>
            {/* Kartta */}
            <div className="flex-1 mt-2">
              <MapEmbed className="w-full min-h-[240px] sm:min-h-[320px] border border-white" />
            </div>
          </div>
        </div>
      </div>
    </Content>
  );
};

export default ContactInfo;
