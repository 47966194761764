import React from 'react';
import { getImagePath } from "../../utils/getImagePath";

const ContactPersonCard = ({ contact }) => {
  return (
    <div className="flex lg:flex-col items-center overflow-hidden md:pl-2">
      <img 
        className="object-cover aspect-square max-w-20 border border-white"
        src={getImagePath(contact.imageFileName)}
        alt="headshot"
        loading="lazy"
      />
      <div className="flex flex-col text-center mt-1">
        <div className="text-lg font-bold">{contact.firstName} {contact.lastName}</div>
        <div className="font-medium">{contact.phoneNumber}</div>
      </div>
    </div>
  );
};

export default ContactPersonCard;
