import React, { useEffect, useCallback } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { BackgroundImageProvider } from './contexts/BackgroundContext';
import PageLayout from './containers/PageLayout';
import MainContent from './content/MainContent';
import Contact from './content/Contact';
import LanguageSwitcher from './components/LanguageSwitcher';
import IctService from './content/IctService';
import DesignService from './content/DesignService';
import SaleService from './content/SaleService'
import ManufacturingService from './content/ManufacturingService';
import ContactInfo from './content/ContactInfo';
import AllContacts from './content/AllContacts'
import Company from './content/Company';
import IctApplication from './content/IctApplication';
import ElectricalApplication from './content/ElectricalApplication';
import AutomationApplication from './content/AutomationApplication';
import TechnicianApplication from './content/TechnicianApplication';
import PrivacyPolicy from './content/PrivacyPolicy';
import ReactGA from "react-ga4";
import CookieConsentBanner from './components/CookieConsentBanner';
import './App.css';
import i18n from './i18n';

function App() {

  // Varmistetaan kielen lataus alussa
  useEffect(() => {
    const currentLng = 'fi'; // Default suomi
    i18n.changeLanguage(currentLng);
  }, []);

  const handleConsentGiven = useCallback(() => {
    const gaConsent = localStorage.getItem('ga-consent');
    if (gaConsent === 'true') {
      ReactGA.send({ hitType: 'pageview' });
    }
  }, []);

  return (
    <GoogleReCaptchaProvider reCaptchaKey="6Lc8JQwqAAAAANwtPAJNyxbuLVpMQ6-QGohHxLch">
      <Router>
        <CookieConsentBanner onConsentGiven={handleConsentGiven} />
        <BackgroundImageProvider>
          <LanguageSwitcher />
          <PageLayout>
            <Routes>
              <Route path="/" element={<MainContent />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/ict" element={<IctService />} />
              <Route path="/design" element={<DesignService />} />
              <Route path="/sale" element={<SaleService />} />
              <Route path="/manufacturing" element={<ManufacturingService />} />
              <Route path="/contactinfo" element={<ContactInfo />} />
              <Route path="/all_contacts" element={<AllContacts />} />
              <Route path="/company" element={<Company />} />
              <Route path="/ict_recruit" element={<IctApplication />} />
              <Route path="/electrical_recruit" element={<ElectricalApplication />} />
              <Route path="/automation_recruit" element={<AutomationApplication />} />
              <Route path="/technician_recruit" element={<TechnicianApplication />} />
              <Route path="/privacy_policy" element={<PrivacyPolicy />} />

            </Routes>
          </PageLayout>
        </BackgroundImageProvider>
      </Router>
    </GoogleReCaptchaProvider>
  );
}

export default App;